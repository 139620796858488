


























import {Component, Prop, Vue} from 'vue-property-decorator';
import {DataTablePagination} from '@/layouts/back-office/elements/datatable/components/DataTablePaginator.vue';

@Component({
  components: {},
})
export default class DataTablePageIndicator extends Vue {
  @Prop(Object) protected pagination!: DataTablePagination;
  @Prop(Number) protected total!: number;
  @Prop(String) protected title!: string | null;
  @Prop(Object) protected back?: object | null;
}
