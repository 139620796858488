


















import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import FilterFactory from '@/layouts/back-office/elements/filters/FilterFactory.vue';
import {Filter, FilterType} from '@/layouts/back-office/elements/filters/FilterMixin';
import dateTime from '@/filters/vue/dateTime';
import ActionMenu, {ActionMenuDirection} from '@/layouts/back-office/elements/ActionMenu.vue';
import {FilterSelectOption} from '@/layouts/back-office/elements/filters/SelectFilter.vue';
import {FilterBooleanOptions} from '@/layouts/back-office/elements/filters/BooleanFilter.vue';
import FilterButton from '@/layouts/back-office/elements/filters/FilterButton.vue';
import UserPresetFilterButton from '@/layouts/back-office/elements/filters/UserPresetFilterButton.vue';

@Component({
  components: {UserPresetFilterButton, FilterButton, ActionMenu, FilterFactory},
})
export default class DataTableFilters extends Vue {
  @Prop({type: String, default: 'filter'}) protected filterKey!: string;
  @Prop(Array) protected filters!: Filter[];
  @Prop(Object) protected values!: any;
  @Prop({type: Number, default: 500}) protected timeout!: number;
  @Prop({type: String, default: 'Snapshots'}) protected title!: string;
  @Prop({type: Boolean, default: false}) protected canClearFilters!: boolean;

  private timeoutCallback?: any;
  private filtersWithValue: Filter[] = [];
  private filterDirection: ActionMenuDirection = ActionMenuDirection.BottomRight;
  private userPresetMenuActive: boolean = false;

  private get clearable() {
    return this.canClearFilters && this.filters.filter((f) => !!f.value).length > 0;
  }

  @Watch('values', {immediate: true})
  private updateFilter(values: any) {
    this.filtersWithValue = this.filters.map((filter: Filter) => {
      filter.value = (filter.key in values)
        ? values[filter.key]
        : null;

      return filter;
    });
  }

  private clear() {
    this.filtersWithValue = this.filters.map((filter: Filter) => {
      filter.value = null;

      return filter;
    });
  }

  private onFilterPresetMenuToggle(value: boolean) {
    this.userPresetMenuActive = value;
    this.$emit('userPresetMenuActiveToggleEvent', this.userPresetMenuActive);
  }

  private filterChanged() {
    clearTimeout(this.timeoutCallback);

    this.timeoutCallback = setTimeout(() => {
      const query = Object.assign({}, this.$route.query);

      this.filters.forEach((filter: Filter) => {
        const queryKey = this.filterKey + '-' + filter.key;

        if (this.isFilled(filter)) {
          const value = filter.value;

          switch (filter.type) {
            case FilterType.QuerySelect:
            case FilterType.Select:
              query[queryKey] = (value) ? value.join(',') : '';
              break;
            case FilterType.Date:
              if (value.start) {
                query[queryKey + ':start'] = dateTime(value.start, 'Y-m-d');
              } else {
                delete query[queryKey + ':start'];
              }
              if (value.end) {
                query[queryKey + ':end'] = dateTime(value.end, 'Y-m-d');
              } else {
                delete query[queryKey + ':end'];
              }
              break;
            default:
              query[queryKey] = value;
          }
        } else {
          switch (filter.type) {
            case FilterType.Date:
              delete query[queryKey + ':start'];
              delete query[queryKey + ':end'];
              break;
            default:
              delete query[queryKey];
          }
        }
      });

      const isCurrentFilter = this.isPreviousFilter(query);
      if (!isCurrentFilter) {
        this.$router.replace({path: this.$route.path, query});
      }
    }, this.timeout);
  }

  /**
   * Method to check if all the filters are already in the current route
   * @private
   */
  private isPreviousFilter(query: any): boolean {
    const currentRoute = this.$router.currentRoute;
    const currentQuery = currentRoute.query;

    if (Object.keys(currentQuery).length !== Object.keys(query).length) {
      return false;
    }

    return Object
      .entries(query)
      .every(([key, value]) => currentQuery.hasOwnProperty(key) && currentQuery[key] === value);
  }

  private isFilled(filter: Filter) {
    const value = filter.value;
    const defaultValue = filter.default;

    if (!defaultValue) {
      return value !== null;
    }

    switch (filter.type) {
      case FilterType.QuerySelect:
      case FilterType.Select:
        return !(value !== null && value.length === defaultValue.length
          && value.every((v: any) => defaultValue.indexOf(v) >= 0));
      case FilterType.Date:
        return value.start !== defaultValue.start && value.end !== defaultValue.end;
      default:
        return value !== defaultValue;
    }
  }
}
