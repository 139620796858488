

































import {Component, Mixins, Prop, Vue, Watch} from 'vue-property-decorator';
import Modal from '@/layouts/back-office/elements/modals/Modal.vue';
import InputText from '@/layouts/back-office/elements/input/InputText.vue';
import MutationModal from '@/layouts/back-office/elements/modals/MutationModal.vue';
import ActionMixin from '@/layouts/back-office/elements/actions/ActionMixin';
import ActionModal from '@/layouts/back-office/elements/actions/ActionModal.vue';

@Component({
  components: {InputText, Modal, MutationModal, ActionMixin, ActionModal},
})

export default class CreateNewPresetButton extends Mixins(ActionMixin) {

  @Prop(String) public userPresetFilterType!: string;

  private presetName: string = '';

  public open() {
    if (this.canCreatePreset) {
      const modal: ActionModal = this.$refs.actionModal as ActionModal;
      modal.open();
    }
  }

  get variables() {
    const variables: any = {
      name: this.presetName,
      query: this.presetQueryFromURL,
      type: this.userPresetFilterType,
    };

    return variables;
  }

  get canSave() {
    const canSave = this.presetName !== null && this.presetName.length > 0 &&
      this.presetQueryFromURL !== null;

    return canSave;
  }

  get presetQueryFromURL() {
    const parametersDict = this.$route.query;

    // return json result if parameters are present in the URL, otherwise return null
    if (!this.isEmpty(parametersDict)) {
      const jsonResult = JSON.stringify(parametersDict);
      return jsonResult;
    } else {
      return null;
    }
  }

  get canCreatePreset() {
    if (this.presetQueryFromURL === null) {
      return false;
    }
    return true;
  }

  private isEmpty(obj: any) {
    return Object.keys(obj).length === 0;
  }

}
