





































import {Component, Emit, Model, Prop, Vue, Watch} from 'vue-property-decorator';
import ActionMenu, {ActionMenuDirection} from '@/layouts/back-office/elements/ActionMenu.vue';
import FilterFactory from '@/layouts/back-office/elements/filters/FilterFactory.vue';
import CreateNewPresetButton from '@/layouts/back-office/elements/filters/CreateNewPresetButton.vue';
import {SmartQuery} from 'vue-apollo/types/vue-apollo';
import {StateChanger} from 'vue-infinite-loading';
import RemoveUserPresetButton from '@/layouts/back-office/elements/filters/RemoveUserPresetButton.vue';

@Component({
  components: {RemoveUserPresetButton, CreateNewPresetButton, FilterFactory, ActionMenu},
})

export default class UserPresetFilterButton extends Vue {

  @Model('change') public model!: any;
  @Prop(String) public userPresetFilterType!: string;

  protected variables!: any;
  protected dataKey: string = 'paginateUserFilterPreset';
  protected value: any = null;

  private userPresetMenuActive: boolean = false;
  private allItems: any[] = [];
  private selected: string[] = [];
  private queryResult?: SmartQuery<any>;
  private stateChanger?: StateChanger;

  get queryVariables() {
    return {
      // limit: 5,
      // page: 1,
      type: this.userPresetFilterType,
    };
  }

  @Watch('model', {immediate: true})
  protected modelChange() {
    this.value = this.model;
  }

  // uncheck a potentially selected radio-box when the url changes
  @Watch('$route')
  protected routeChange() {
    if (!this.userPresetMenuActive) {
      this.selected = [];
    }
  }

  private setItems(items: any) {
    items.forEach((item: any) => {
      if (this.allItems.findIndex((i: any) => i.id === item.id) < 0) {
        this.allItems.push(item);
      }
    });
  }

  private toggleUserPresetMenu() {
    this.userPresetMenuActive = !this.userPresetMenuActive;
  }

  @Emit('filterPresetMenuToggle')
  @Watch('userPresetMenuActive')
  private toggleChange(value: any) {
    return value;
  }

  @Emit('change')
  @Watch('value')
  private valueChange(value: any) {
    return value;
  }

  @Watch('done')
  private done() {
    this.refresh();
  }

  @Watch('selected')
  private selectedChanged(selected: string[]) {
    const queryOfSelectedUserFilterPreset = this.allItems
      .filter((item: any) => selected.indexOf(item.id) >= 0)
      .map((item: any) => item.query)[0];

    if (queryOfSelectedUserFilterPreset) {
      const parameters = JSON.parse(queryOfSelectedUserFilterPreset);
      this.$router.replace({query: parameters});
    }
  }

  private resolveData(data: any, query: SmartQuery<any>): object[] {
    this.queryResult = query;

    if (data && this.dataKey in data) {
      data = data[this.dataKey];

      this.setItems(data);

      return ('data' in data)
        ? data.data
        : data;
    }

    return data;
  }

  private refresh() {
    if (this.queryResult) {
      this.queryResult.refetch();
    }
    if (this.stateChanger) {
      this.stateChanger.reset();
    }
  }

}
