import {Component, Prop, Vue} from 'vue-property-decorator';
import {Filter, FilterType} from '@/layouts/back-office/elements/filters/FilterMixin';
import hash from 'object-hash';
import {Tooltip} from '@/plugins/misc/Tooltip';

export interface DataTableSearchable {
  key: string;
  name?: string;
}

export interface DataTableSort {
  field: string;
  direction: 'asc' | 'desc';
}

export interface DataTableColumn {
  key: string;
  title?: string;
  tooltip?: Tooltip;
  width?: number;
  sortable?: boolean | string;
  slot?: string;
  class?: string;
  classTitle?: string;
  permission?: string;
  select?: boolean;
}

export interface InternalDataTableColumn extends DataTableColumn {
  getter: any; // This is not ok, but it prevents an error
  styles: string;
  classes: string;
  sortKey: string;
}

@Component
export default class DataTableMixin extends Vue {
  @Prop({type: String, default: 'id'}) public keyField!: string;
  @Prop({type: String, default: ''}) public dataTableId!: string;
  @Prop({type: Boolean, default: true}) public header!: boolean;
  @Prop({type: Array, required: true}) public columns!: DataTableColumn[];
  @Prop({type: Array, default: () => []}) public filters!: Filter[];
  @Prop({type: Array, default: () => []}) public searchableFields!: DataTableSearchable[];

  @Prop({type: String, default: ''}) public defaultSort!: string;
  @Prop({type: String, default: 'desc', validator: (value: string) => ['asc', 'desc'].indexOf(value) >= 0 })
  public defaultSortDirection!: string;

  @Prop({type: Boolean, default: true}) public bordered!: boolean;
  @Prop({type: Boolean, default: true}) public recycleScroller!: boolean;
  @Prop({type: Boolean, default: false}) public canClearFilters!: boolean;
  @Prop(Object) public back?: object;
  @Prop(Number) public buffer?: number;

  public identifier: string = '';
  public pageKey: string = 'page';
  public searchKey: string = 'search';
  public sortKey: string = 'sort';
  public filterKey: string = 'filter';

  protected created() {
    if (this.dataTableId.length > 0) {
      this.setQueryKeys(this.dataTableId);
    }
  }

  protected setQueryKeys(identifier: string) {
    this.identifier = identifier;

    this.pageKey = this.identifier + '-page';
    this.searchKey = this.identifier + '-search';
    this.sortKey = this.identifier + '-sort';
    this.filterKey = this.identifier + '-filter';
  }

  get availableColumns() {
    return this.columns.filter((column: DataTableColumn) => {
      return column.permission === undefined || this.$can(column.permission);
    });
  }

  get availableFilters() {
    return this.filters.filter((filter: Filter) => {
      return filter.permission === undefined || this.$can(filter.permission);
    });
  }

  get queryIdentifier() {
    return this.identifier + hash(this.queryVariables);
  }

  get sortVariables(): DataTableSort | null {
    const queryKeys = Object.keys(this.$route.query);

    if (queryKeys.indexOf(this.sortKey) >= 0) {
      const sortQuery = this.$route.query[this.sortKey] as string;
      const sort = sortQuery.split(',');
      const sortField = sort[0];
      const sortDirection = sort[1];

      return {
        field: sortField,
        direction: (sortDirection === 'asc' || sortDirection === 'desc') ? sortDirection : 'desc',
      };
    } else if (this.defaultSort !== null && this.defaultSort.length > 0) {
      return {
        field: this.defaultSort,
        direction: (this.defaultSortDirection === 'asc' || this.defaultSortDirection === 'desc')
          ? this.defaultSortDirection
          : 'desc',
      };
    }

    return null;
  }

  get queryVariables() {
    const variables: any = {page: '1'};
    const queryKeys = Object.keys(this.$route.query);

    if (queryKeys.indexOf(this.pageKey) >= 0) {
      variables.page = this.$route.query[this.pageKey];
    }

    if (queryKeys.indexOf(this.searchKey) >= 0) {
      variables.search = this.$route.query[this.searchKey];
      variables.searchableFields = this.searchableFieldsKeys;
    }

    if (this.sortVariables) {
      variables.sortBy = this.sortVariables.field;
      variables.sortDirection = this.sortVariables.direction;
      variables.orderBy = [{
        field: this.sortVariables.field,
        order: this.sortVariables.direction.toUpperCase(),
      }];
    }

    this.availableFilters.forEach((filter: Filter) => {
      const key = filter.key;
      const filterKeys = queryKeys.filter((queryKey) => queryKey.startsWith(this.filterKey + '-' + key));
      const filterKey = filterKeys[0];

      if (filterKey) {
        const value: string = this.$route.query[filterKey].toString();

        switch (filter.type) {
          case FilterType.QuerySelect:
          case FilterType.Select:
            variables[key] = value.split(',').filter(((val) => val.length > 0));
            break;
          case FilterType.Boolean:
            variables[key] = (value === 'true');
            break;
          case FilterType.Date:
            const dateVariable: any = {};
            filterKeys.forEach((f: string) => {
              const dateKey = f.replace(this.filterKey + '-' + key + ':', '');

              const dateValue = this.$route.query[f].toString();
              const localDate = new Date(dateValue);
              dateVariable[dateKey] = new Date(
                Date.UTC(localDate.getUTCFullYear(), localDate.getUTCMonth(), localDate.getUTCDate(),
                  12, 0, 0),
              );
            });

            variables[key] = dateVariable;
            break;
        }
      } else if ('default' in filter) {
        variables[key] = filter.default;
      }
    });

    return variables;
  }

  get columnSlots(): string[] {
    return this.columns
      .reduce((slots: string[], column: DataTableColumn) => {
        const key = column.slot || column.key;

        if (key in this.$scopedSlots && slots.indexOf(key) < 0) {
          slots.push(key);
        }

        return slots;
      }, []);
  }

  get searchableFieldsKeys(): string[] {
    return this.searchableFields
      .map((searchable: DataTableSearchable) => searchable.key);
  }

  get searchable(): boolean {
    return this.searchableFields.length > 0;
  }

  get selectable(): boolean {
    return 'actions' in this.$scopedSlots;
  }

  get filterable(): boolean {
    return this.filters.length > 0;
  }
}
