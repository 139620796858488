





















import {Component, Prop, Vue} from 'vue-property-decorator';

export interface DataTablePagination {
  from: number | null;
  to: number | null;
  currentPage: number;
  perPage: number;
  lastPage: number;
  hasMorePages: boolean;
  total: number;
  infiniteLoading?: boolean;
}

@Component({})
export default class DataTablePaginator extends Vue {
  @Prop({type: String, default: 'page'}) protected pageKey!: string;
  @Prop(Object) protected pagination!: DataTablePagination;

  get sequence() {
    const d = [];
    let min = Math.max(0, Math.min(this.pagination.currentPage - 2, this.pagination.lastPage - 5)) + 1;
    const max = Math.min(this.pagination.lastPage, Math.max(5, this.pagination.currentPage + 3)) + 1;
    for (min; min < max; ++min) {
      d.push(min);
    }
    return d;
  }

  private revolveUrl(page: number): object {
    const query = Object.assign({}, this.$route.query);

    query[this.pageKey] = page + '';

    return {
      path: this.$route.path,
      query,
    };
  }
}
