

























import {Component, Mixins} from 'vue-property-decorator';
import ActionMenu, {ActionMenuDirection} from '@/layouts/back-office/elements/ActionMenu.vue';
import ActionsRegistrarMixin from '@/layouts/back-office/elements/actions/ActionsRegistrarMixin';

@Component({
  components: {ActionMenu},
})
export default class DataTableActions extends Mixins(ActionsRegistrarMixin) {
  private direction: ActionMenuDirection = ActionMenuDirection.BottomRight;
}
