
















































































































































































import {Component, Emit, Mixins, Prop, Watch} from 'vue-property-decorator';
import ActionBar from '@/layouts/back-office/elements/ActionBar.vue';
import DataTableMixin, {
  DataTableColumn, InternalDataTableColumn,
} from '@/layouts/back-office/elements/datatable/DataTableMixin';
import DataTableActions from '@/layouts/back-office/elements/datatable/components/DataTableActions.vue';
import DataTablePaginator, {
  // @ts-ignore
  DataTablePagination,
} from '@/layouts/back-office/elements/datatable/components/DataTablePaginator.vue';
import DataTableSearchBar from '@/layouts/back-office/elements/datatable/components/DataTableSearchBar.vue';
import Spinner from '@/layouts/back-office/elements/Spinner.vue';
import Card from '@/layouts/back-office/elements/Card.vue';
import DataTablePageIndicator from '@/layouts/back-office/elements/datatable/components/DataTablePageIndicator.vue';
import FilterFactory from '@/layouts/back-office/elements/filters/FilterFactory.vue';
import DataTableFilters from '@/layouts/back-office/elements/datatable/components/DataTableFilters.vue';
import {StateChanger} from 'vue-infinite-loading';
import {RecycleScroller} from 'vue-virtual-scroller';

@Component({
  components: {
    ActionBar,
    DataTableFilters,
    FilterFactory,
    DataTablePageIndicator,
    Card,
    Spinner,
    DataTableSearchBar,
    DataTablePaginator,
    DataTableActions,
    RecycleScroller,
  },
})
export default class DataTable extends Mixins(DataTableMixin) {
  @Prop({type: Boolean, default: false}) protected loading!: boolean;
  @Prop(String) protected error: string | undefined;
  @Prop({type: Array, default: () => []}) protected data!: object[];
  @Prop(Object) protected pagination?: DataTablePagination;
  @Prop(String) protected title!: string | null;
  @Prop(String) protected cardTitle!: string | null;
  @Prop(Number) protected maxHeight!: number | null;
  @Prop({type: Number, default: 62}) protected rowHeight!: number;
  @Prop({type: Array, default: () => []}) protected bulkChangeItems!: string[];
  @Prop({type: Array, default: () => []}) protected fieldsBeingChanged!: string[];
  @Prop({type: Boolean, default: true}) protected offlineSort!: boolean;

  @Prop({type: Boolean, default: true}) protected extend!: boolean;

  private userPresetMenuActive: boolean = false;
  private selectAll: boolean = false;
  private selected: number[] = [];

  private sortBy: string = '';
  private sortDirection: string = '';
  private pageIndex = 1;

  private mounted() {
    // Reset the page index upon re-render
    this.pageIndex = 1;
  }

  private get hasActionsHeader(): boolean {
    return !!(this.title || this.selectable || this.filterable || this.searchable || this.pagination);
  }

  private get internalColumns(): InternalDataTableColumn[] {
    return this.availableColumns.map((c) => {
      const ic = c as InternalDataTableColumn;

      if ('sortable' in c && c.sortable !== false) {
        ic.sortKey = (typeof c.sortable === 'string') ? c.sortable : c.key;
      } else {
        ic.sortable = false;
      }

      ic.classes = this.classes(ic);
      ic.styles = this.styles(ic);
      ic.getter = new Function('obj', 'try{return obj.' + c.key + ';}catch(e){} return null;');

      return ic;
    });
  }

  private get sortedData() {
    if (!this.offlineSort || !this.sortVariables) {
      return this.data;
    }

    const fieldName = this.sortVariables.field;

    const isDescending = this.sortVariables!.direction === 'desc';

    return this.data.sort((a: any, b: any) => {
      const aValue = a[fieldName];
      const bValue = b[fieldName];
      if (aValue instanceof Date || bValue instanceof Date) {
        const aValueTime = aValue.getTime();
        const bValueTime = bValue.getTime();
        if (aValueTime < bValueTime) {
          return (isDescending) ? 1 : -1;
        }
        if (aValueTime > bValueTime) {
          return (isDescending) ? -1 : 1;
        }
        return 0;
      }
      if (aValue < bValue) {
        return (isDescending) ? -1 : 1;
      }
      if (aValue > bValue) {
        return (isDescending) ? 1 : -1;
      }
      return 0;
    });
  }

  private onSelectAllChange(selectAll: boolean) {
    this.selected = (this.selectAll)
      ? this.data.map((item: any) => item[this.keyField])
      : [];
  }

  @Watch('selected')
  @Emit('select')
  private onSelected(selected: number[]) {
    this.selectAll = this.data.length > 0 && this.data.every((item: any) => selected.indexOf(item[this.keyField]) >= 0);

    return this.selectedItems;
  }

  @Watch('queryVariables.sortBy', {immediate: true})
  @Watch('queryVariables.sortDirection')
  private onSortChanged() {
    this.sortBy = this.queryVariables.sortBy || '';
    this.sortDirection = this.queryVariables.sortDirection || '';
  }

  private get selectedItems() {
    return this.data.filter((item: any) => this.selected.indexOf(item[this.keyField]) >= 0);
  }

  private select(id: number, column?: DataTableColumn) {
    if (this.selectable && (column === undefined || column.select !== false)) {
      const index = this.selected.indexOf(id);
      if (index > -1) {
        this.selected.splice(index, 1);
      } else {
        this.selected.push(id);
      }
    }
  }

  private styles(column: DataTableColumn) {
    return column.width ? `width: ${column.width}px` : '';
  }

  private classes(column: InternalDataTableColumn) {
    const classes: string[] = [];

    if (column.sortable !== false) {
      classes.push('sortable');

      if (this.sortBy === column.sortKey) {
        classes.push('sorted');
      }
    }

    if (column.class) {
      classes.push(column.class);
    }

    return classes.join(' ');
  }

  private sort(column: InternalDataTableColumn) {
    if (column.sortable) {
      if (this.sortBy !== column.sortKey) {
        this.sortBy = column.sortKey;
        this.sortDirection = 'desc';
      } else if (this.sortDirection === 'desc') {
        this.sortDirection = 'asc';
      } else {
        this.sortBy = '';
        this.sortDirection = '';
      }

      const query = Object.assign({}, this.$route.query);
      if (this.sortBy.length > 0) {
        query[this.sortKey] = [this.sortBy, this.sortDirection].join(',');
      } else {
        delete query[this.sortKey];
      }
      this.$router.replace({path: this.$route.path, query});

      this.$emit('sort', this.sortBy, this.sortDirection);
    }
  }

  private get pageSize() {
    return this.pagination && this.pagination.perPage ? this.pagination.perPage : 25;
  }

  private loadMore(stateChanger: StateChanger) {
    if (this.data.length > this.pageIndex * this.pageSize) {
      this.pageIndex++;
      stateChanger.loaded();
      return;
    }

    if (this.pagination && !this.pagination.hasMorePages) {
      stateChanger.complete();
      return;
    }

    this.$emit('loadMore', stateChanger, this.pagination);
  }

  private isNonActiveRow(itemId: number) {
    // only set class if bulkChangeItems is filled
    if (this.bulkChangeItems && this.bulkChangeItems.length) {
      // not empty
      return !this.bulkChangeItems.includes(itemId.toString());
    }
  }

  private isActiveRow(itemId: number) {
    // only set class if bulkChangeItems is filled
    if (this.bulkChangeItems && this.bulkChangeItems.length) {
      // not empty
      return this.bulkChangeItems.includes(itemId.toString());
    }
  }

  private columnBeingChanged(column: any) {
    if (this.fieldsBeingChanged.includes(column.key)) {
      return true;
    }
  }
}

