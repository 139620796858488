



import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({
  components: {},
})
export default class UserPresetFilterButton extends Vue {

  @Prop(String) protected itemID!: string | null;

  protected mutation: any = require('@/graphql/mutations/DeleteUserFilterPreset.gql');
  protected saving: boolean = false;
  protected error: string | null = null;

  get variables() {
    const variables: any = {id: this.itemID};
    return variables;
  }

  protected onErrorsReceived(errors: string[]) {
    this.$emit('errors', errors);
  }

  protected async save() {
    this.error = null;

    try {
      const result = await this.$apollo.mutate({
        mutation: this.mutation,
        variables: this.variables});

      if (result) {
        const data: any = Object.values(result.data)[0];
        if ('errors' in data && data.errors.length > 0) {
          this.onErrorsReceived(data.errors);
        }
      }

      this.$emit('done');

    } catch (error) {
      this.error = error.message;
    }
  }

  private deleteUserFilterPreset() {
    this.save();
  }
}

